<template>
  <section id="sales">
    <div class="whoweare-mobile">
      <WhoWeAreMobile />
    </div>
    <div class="sales-cnt">
      <div class="sales-title-cnt">
        <div class="sales-title">Sales</div>
        <div class="line-divider"></div>
      </div>
      <div class="sales-desc">
        Meet your first-round draft picks. The Sales team will play an essential
        role in tailoring Allegiant Stadium’s event spaces to your specific
        needs.
      </div>
      <div class="rep-cnt">
        <div class="repBox half" v-for="rep in reps" :key="rep.name">
          <img
            :src="
              require('@/assets/images/VenueOverview/whoWeAre/reps/' +
                rep.image)
            "
            :alt="rep.name"
            class="repImg"
          />
          <div class="rep-name">{{ rep.name }}</div>
          <div class="rep-title">{{ rep.title }}</div>
          <div class="rep-desc">
            {{ rep.description }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import WhoWeAreMobile from "@/components/sections/Venue/WhoWeAre/WhoWeAreMobile";

export default {
  name: "sales",
  components: {
    WhoWeAreMobile,
  },
  data() {
    return {
      reps: [
        {
          image: "rep-samantha.png",
          name: "Samantha Johnson",
          title: "Senior Director of Sales and Marketing",
          description:
            "Samantha (Sam) combines her passion for event planning with her expertise in stadium operations to ensure each event is successful at Allegiant Stadium.",
        },
        {
          image: "rep-ashley.png",
          name: "Ashley Hinsen",
          title: "Senior Director of Sales",
          description:
            "Ashley curates out of the box experiences by channeling her event design background to guide each client into an unforgettable experience at Allegiant Stadium.",
        },
        {
          image: "rep-carissa.png",
          name: "Carissa Callaway",
          title: "Sales Manager",
          description:
            "Carissa enjoys creating one-of-a-kind experiences for her customers by channeling her experiences in executing high end events and building strong partnerships with her clients at Allegiant Stadium.",
        },
        {
          image: "rep-anja.png",
          name: "Anja Marcusiu",
          title: "Sales Manager",
          description:
            "Anja specializes in delivering world-class events by turning client’s visions into realities for an extraordinary experience.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#sales {
  width: 100vw;
  height: 100vh;
  background: url("~@/assets/images/VenueOverview/whoWeAre/bg-sales.png") scroll
    no-repeat center center;
  background-size: cover;
  @media (max-width: $lg) {
    padding: 0;
    height: auto;
  }
  .sales-cnt {
    position: relative;
    display: flex;
    height: 100%;
    overflow: auto;
    flex-direction: column;
    justify-content: flex-start;
    padding: 160px 30px 160px 298px;
    @media (max-width: $lg) {
      display: none;
    }
    .sales-title-cnt {
      display: inline-flex;
      flex-wrap: wrap;
      align-self: flex-start;
      margin-bottom: 25px;
      .sales-title {
        width: 100%;
        margin-bottom: 25px;
        font-size: 35px;
        font-family: "ArtegraSans-Bold";
        line-height: 30px;
        letter-spacing: 2.19px;
        text-transform: uppercase;
        color: #ffffff;
      }
      .line-divider {
        position: relative;
        width: 100%;
        height: 2px;
        background: #ffffff;
        box-shadow: 0 0 9px 2px #ffffff;
      }
    }
    .sales-desc {
      max-width: 653px;
      font-size: 16px;
      font-family: "ArtegraSans-Regular";
      line-height: 23px;
      letter-spacing: 1.07px;
      color: #ffffff;
      @media (min-width: 2000px) {
        font-size: 26px;
        line-height: 37px;
        max-width: 50%;
      }
    }
    .rep-cnt {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      margin-top: 40px;
      gap: 58px;
      .repBox {
        flex: 1;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        @media (max-width: 1250px) {
          flex-basis: calc(50% - 58px);
        }
        .repImg {
          margin-bottom: 10px;
          width: 200px;
          @media (min-width: 2000px) {
            width: 300px;
          }
        }
        .rep-name {
          display: block;
          width: 100%;
          font-size: 16px;
          font-family: "ArtegraSans-Bold";
          letter-spacing: 1px;
          line-height: 30px;
          text-align: center;
          text-transform: uppercase;
          color: #ffffff;
          @media (min-width: 2000px) {
            font-size: 30px;
            line-height: 50px;
          }
        }
        .rep-title {
          display: block;
          width: 100%;
          font-size: 14px;
          font-family: "ArtegraSans-Bold";
          line-height: 18px;
          letter-spacing: 0.88px;
          text-align: center;
          text-transform: uppercase;
          color: #989898;

          @media (min-width: 2000px) {
            font-size: 26px;
            line-height: 37px;
          }
        }
        .rep-desc {
          display: block;
          width: 100%;
          margin-top: 20px;
          font-size: 19px;
          font-family: "ArtegraSans-Regular";
          line-height: 25px;
          letter-spacing: 1.19px;
          text-align: center;
          color: #ffffff;

          @media (min-width: 2000px) {
            font-size: 26px;
            line-height: 37px;
          }
        }
      }
    }
  }
  .whoweare-mobile {
    display: none;
    @media (max-width: $lg) {
      display: block;
    }
  }
}
</style>
